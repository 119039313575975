import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-595d6cdd")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex gap-2" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["onClick"]
_popScopeId()


import { useP3miListStore } from "@/store/pinia/gws-master/master-data/p3mi/useP3miListStore";
import { useP3miFormStore } from "@/store/pinia/gws-master/master-data/p3mi/useP3miFormStore";
import { ListItem } from "@/core/types/gws-master/master/p3mi/ListItem";
import { simpleFormatDate } from "@/core/helpers/date-format";
import { checkButtonPermission } from "@/core/helpers/helpers-utils";
//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  emits: ["delete-data", "edit-data", "toggle-status"],
  setup(__props, { emit: emits }) {

const props = __props

const listStore = useP3miListStore();
const formStore = useP3miFormStore();


const handleEditData = (item: ListItem) => {
  const editRow = {
    name: item.name,
    address: item.address,
    siup: item.siup,
    npwp: item.npwp,
    email: item.user.email,
    sip: item.sip,
    password: "",
    sip_file: item.sip_file,
    sip_start_date: item.sip_start_date,
    sip_end_date: item.sip_end_date,
    pic_name: item.pic_name,
    province_id: item.province.id,
    city_id: item.city.id,
    pic_id_number: item.pic_id_number,
    pic_email: item.pic_email,
    pic_phone: item.pic_phone,
  };
  formStore.setFormData(editRow);
  emits("edit-data", item.id);
};
const handleToggleStatus = (event) => {
  emits("toggle-status", event);
};
const handleDeleteData = (event) => {
  emits("delete-data", event);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            label: "No.",
            type: "index",
            "min-width": "20"
          }),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "Nama",
            "min-width": "100",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            prop: "id",
            label: "ID Perusahaan",
            "min-width": "100"
          }),
          _createVNode(_component_el_table_column, {
            prop: "sip",
            label: "Nomor SIP"
          }),
          _createVNode(_component_el_table_column, {
            prop: "sip_start_date",
            label: "Tanggal Terbit"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_unref(simpleFormatDate)(scope.row.sip_start_date)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "sip_end_date",
            label: "Tanggal Berakhir"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_unref(simpleFormatDate)(scope.row.sip_end_date)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "is_active",
            label: "Status"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_switch, {
                modelValue: scope.row.is_active,
                "onUpdate:modelValue": ($event: any) => (scope.row.is_active = $event),
                class: "ml-2",
                style: {"--el-switch-on-color":"#13ce66","--el-switch-off-color":"#ff4949"},
                onChange: ($event: any) => (handleToggleStatus(scope.row))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "action",
            label: "Aksi",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                (_unref(checkButtonPermission)('U'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn-edit",
                      id: scope.id
                    }, [
                      _createVNode(_component_inline_svg, {
                        src: `media/gws/pencil-alt.svg`,
                        onClick: ($event: any) => (handleEditData(scope.row))
                      }, null, 8, ["src", "onClick"])
                    ], 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (_unref(checkButtonPermission)('D'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn-delete",
                      onClick: ($event: any) => (handleDeleteData(scope.row))
                    }, [
                      _createVNode(_component_inline_svg, { src: `media/gws/trash.svg` }, null, 8, ["src"])
                    ], 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})